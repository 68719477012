// document.addEventListener('DOMContentLoaded', function() {

//   const header = document.querySelector("header");
//   const logo = document.querySelector(".logo_image");
//   const subtitle = document.querySelector(".subtitle");
//   const sectionOne = document.querySelector(".home__intro");

//   const faders = document.querySelectorAll(".fade-in");
//   const sliders = document.querySelectorAll(".slide-in");

//   const sectionOneOptions = {
//     rootMargin: "-200px 0px 0px 0px"
//   };

// const sectionOneObserver = new IntersectionObserver(function(
//   entries,
//   sectionOneObserver
// ) {
//   entries.forEach(entry => {
//     if (!entry.isIntersecting) {
//       header.classList.add("nav-scrolled");
//       logo.classList.add("logo_image_scrolled");
//       subtitle.classList.add("subtitle_scrolled");
//     } else {
//       header.classList.remove("nav-scrolled");
//       logo.classList.remove("logo_image_scrolled");
//       subtitle.classList.remove("subtitle_scrolled");
//     }
//   });
// },
// sectionOneOptions);

// sectionOneObserver.observe(sectionOne);

// const appearOptions = {
//   threshold: 0,
//   rootMargin: "0px 0px -250px 0px"
// };

// const appearOnScroll = new IntersectionObserver(
//   function(entries, appearOnScroll) {
//     entries.forEach(entry => {
//       if (!entry.isIntersecting) {
//         return;
//       } else {
//         entry.target.classList.add("appear");
//         appearOnScroll.unobserve(entry.target);
//       }
//     });
//   },
//   appearOptions);

//   faders.forEach(fader => {
//     appearOnScroll.observe(fader);
//   });

//   sliders.forEach(slider => {
//     appearOnScroll.observe(slider);
//   });


// }, false);